import { DocumentData, DocumentReference } from "firebase/firestore";
import { Moment } from "moment";

export interface User {
  email: string;
  uid: string;
  syncEmployeeId?: string;
  admin?: boolean;
  loading?: boolean;
  companies?: string[];
  name: Name;
}
export interface Contact {
  email?: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  benefit: number;
  [key: string]: any;
}
export interface UserSearchResults {
  users: User[]
}
export interface UserSearchVariables {
  filters: string;
  page?: number;
  limit?: number;
}

export interface ApplicationPlanDataInput {
  planName: string;
  carrierName: string;
  planType: string;
  state: string;
  effectiveDate: string;
  enrollmentSite: string;
  premiumAmount: string;
}
export interface ApplicationResponse {
  applicationId: string;
  success: boolean;
  message: string;
  error?: string;
}
export interface Service {
  name: string;
  category: string;
  provider: string;
  description: string;
  logo?: string | Element | SVGSVGElement | any;
  background: string;
  color: string;
  cta: boolean;
  ctaText: string;
  ctaIcon?: any;
  tulips: boolean;
  title: string;
  poweredBy: boolean;
  id?: string;
  ref?: DocumentReference<DocumentData>;
}

export interface QuotitHealthPlanDetails {
  id: string,
  benefitLevel: string,
  benefitsLink: string,
  providerLink: string,
  summaryOfBenefitsLink: string,
  exclusionsLink: string,
  disclaimer: string,
  rate: number,
  rateSubsidized?: number,//ideon
  premium?: number,//ideon
  premiumSubsidized?: number,//ideon
  onMarket?: boolean,//ideon
  offMarket?: boolean,//ideon
  name: string,
  carrierId: string,
  effectiveDate: string,
  contractStatus: string,
  visibility: string,
  source: string,
  ratingMethod: string,
  baseRateUnit: string,
  carrier: { name: string, logo: string, largeLogo: string },
  insuranceType: string,
  planType: string,
  benefits: QuotitHealthBenefit[],
  rating: {
    overall: string,
    medical: string,
    experience: string,
    administration: string,
  }
  recommendation: {
    internalScore: number,
    aiScore: number,
    headline: string,
    explanation: string,
    pros: string[],
    cons: string[]
  }
}
export interface QuotitHealthBenefit {
  category: string,
  enum: string,
  inNetwork: string,
  outOfNetwork: string,
  exclusions: string
}

export enum InsuranceType {
  federalExchangePlan = "HealthOnExchange",
  privateMedicalPlan = "HealthOffExchange",
  shortTermPlan = "ShortTerm"
}

export interface HealthPlanDetails {
  id: string,
  source: string,
  premium: number,
  name: string,
  provider: string,
  type: string,
  maxOutOfPocket: number,
  deductible: number,
  metal: string
  rating: number,
  market: string,
  diseasePrograms: string[],
  benefits: HealthBenefit[]
  benefitUrl: string,
  formularyUrl: string,
  brochureUrl: string,
  networkUrl: string
}

export interface HealthBenefit {
  name: string,
  covered: boolean,
  inNetwork: string,
  outOfNetwork: string
}

export interface RecommendPlansData {
  plans: {
    lowestDeductible: HealthPlanDetails,
    lowestPremium: HealthPlanDetails,
    lowestOutOfPocket: HealthPlanDetails
  }
  total: number;
}

export interface RecommendPlansVariables {
  filters?: string;
}
export interface SearchPlansData {
  plans: HealthPlanDetails[];
  page?: number;
  total: number;
}

export interface Opportunity {
  name: string;
  opportunity: any;
  producerAccountId: string;
  producerId: string
  stage: string;
  id?: string;
  ref?: DocumentReference<DocumentData>;
}
export interface BenefitLevel {
  limit: keyof typeof BenefitLimits;
  individual: number;
  family: number;
}
export interface BenefitLevelRates {
  limit: keyof typeof BenefitLimits;
  individual: number;
  family: number;
}
export interface RatingFactor {
  name: string;
  value: number;
  reason: string;
}
export enum PaymentTerms {
  MONTHLY = "Monthly",
  QUARTERLY = "Quarterly",
  SEMI_ANNUALLY = "Semi-Annually",
  ANNUALLY = "Annually",
}
export const BenefitLimits = [
  1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
  5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000
]
export interface QuotePricing {
  premium: number;
  tax: number;
  expectedCommission: number;
  when: {
    gross: number;
    net: number;
  }
}
export interface Quote {
  name?: string;
  effectiveDate?: string;
  benefitLevels: BenefitLevel[];
  policyType?: string;
  stage?: string;
  opportunityId?: string;
  ratingFactors?: RatingFactor[];
  rateTable?: string;
  pricing?: QuotePricing;
  paymentTerms?: PaymentTerms;
  pepm?: BenefitLevelRates[];
  id?: string;
  ref?: DocumentReference<DocumentData>;
}
export interface SearchPlansVariables {
  filters?: string;
  page?: number;
}

export enum IsolvedTrackingEvents {
  ViewedIsolvedLandingPage = "ViewedIsolvedLandingPage",
  IsolvedUserValidationAttempted = "IsolvedUserValidationAttempted",
  UserRedirectedToIsolved = "UserRedirectedToIsolved",
  IsolvedUserValidationSuccessful = "IsolvedUserValidationSuccessful",
}

export interface Company {
  code: string;
  name: string;
  logo: string;
  hrisSetup: boolean; // has Merge been setup
  accountToken?: string; // Merge account token, not accessible to client
  hris?: {
    image: string;
    name: string;
    imported: boolean;
    setup: boolean;
    lastImport?: Moment;
    squareImage: string;
  },
  landingPages?: Array<string>;
  serviceCategoryList?: Array<string>;
  verificationStep?: boolean;
  emailDomain: Array<string>;
  branding?: companyBranding;
  serviceMarketPlace?: ServiceMarketPlaceType;
  createdOn: Moment;
  id?: string;
  ref?: DocumentReference<Company>;
}
export type ExitLetter = {
  name: string;
  notes: string;
  body: string;
  active: boolean;
  deletedAt?: Moment | null;
  id?: string;
  ref?: DocumentReference<DocumentData>;
}
export type Task = {
  name: string;
  deadline?: TaskDeadline;
  type: TaskTypes;
  caseId: string;
  companyId: string;
  caseName: string;
  assignedTo?: string[];
  assignedToUsers?: Array<{ id: string, name: Name, email: string }>;
  status: TaskStatus;
  notes?: string;
  createdAt?: Moment;
  createdBy?: string;
  updatedAt?: Moment;
  updatedBy?: string;
  completedOn?: Moment;
  completedBy?: string;
  completedByUid?: string;
  id?: string;
  ref?: DocumentReference<DocumentData>;
}
export type TaskDeadline = {
  date?: Moment;
  days: number;
  beforeOrAfter: 'before' | 'after';
}
export enum TaskStatus {
  NEW = 'New',
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  COMPLETE = 'Complete',
  WAITING_APPROVAL = 'Waiting Approval',
  CANCELLED = "Cancelled",
  DELETED = "Deleted"
}
export enum BenefitTier {
  individual = "individual",
  family = "family",
  none = "none",
}
export type TerminationData = {
  terminationDate?: Moment | null;
  terminationType: "voluntary" | "involuntary"
  lastDay?: Moment | null;
  reason?: string;
  checklist?: string;
  letter?: string;
  notes?: string;
  scheduled?: boolean;
  benefitTier?: BenefitTier;
}
export type SeverancePackage = {
  name: string;
  description: string;
  active: boolean;
  deletedAt?: Moment | null;
  id?: string;
  ref?: DocumentReference<DocumentData>;
}
export type CaseSeveranceBenefit = SeveranceBenefit & {
  amount: number;
  value: number;
  active: boolean;
}
export type SeveranceBenefit = {
  active: boolean;
  type: SeveranceBenefitType;
  name: string;
  amountOrCoverage?: number;
  term?: SeveranceBenefitTerm;
  termFrequency?: number;
  serviceId?: string;
  conditions?: Condition[];
  structure?: SeveranceBenefitStructure
  id?: string;
  ref?: DocumentReference<DocumentData>;
  calculation: {
    percent: number
    periods: number
    flatAmount?: number
    individualAmount?: number
    familyAmount?: number
    periodMeasure: "weeks" | "months" | "quarters" | "years"
    variableMeasure: "years"
    variablePeriod: number,
    round: "down" | "up" | "round"
  };
}



export type Condition = {
  id: string;
  type: SeveranceConditionType;
  threshold: number;
}
export type SetupData = {
  companyName: string;
  employeeCount: number;
  country: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  title: string
}
// Define the EmployeeInput type
export type EmployeeInput = {
  id: string;
  LastName: string;
  FirstName: string;
  BirthDate: string;
  Gender?: string;
  HomeAddressLine1: string;
  HomeAddressLine2?: string;
  City: string;
  State: string;
  PostalCode: string;
  PersonalEmailAddress: string;
  PersonalPhoneNumber?: string;
  Title?: string;
  Department?: string;
  WorkLocation?: string;
  Compensation?: string;
  CompensationType?: string;
  PayFrequency?: string;
  FLSAStatus?: string;
  EmployeeStatus: string;
  DateOfHire: string;
  TerminationDate: string;
  EmployeeTimeType?: string;
}

export type CobraPlan = {
  cobra: {
    planComparison: {
      carrier: string,
      coverageTierStructure: string,
      createdAt: { seconds: 1709813019, nanoseconds: 874000000 },
      familyDeductible: number,
      familyOutOfPocketMaximum: number,
      individualDeductible: number,
      individualOutOfPocketMaximum: number,
      name: string,
      plan: string[],
      planEndDate: string,
      planNetworkType: string,
      planStartDate: string,
      threeTierPremium: {
        employeeOnly: {
          employeeContribution: number,
          employerContribution: number,
          totalPremium: number
        },
        employeePlusMoreDependent: {
          employeeContribution: number,
          employerContribution: number,
          totalPremium: number
        },
        employeePlusOneDependent: {
          employeeContribution: number,
          employerContribution: number,
          totalPremium: number
        }
      },
    }
  }
}
export type BulkCaseCreateInput = {
  companyCode: string,
  employeeID: string,
  personalEmail: string,
  personalPhone?: string,
  terminationDate: string,
  lastDayOfWork: string,
  terminationType: string,
  startingChecklist?: string,
  benefitTier?: string,
  currentMonthlyBenefitsCost?: number,
  monthlyCobraEstimate?: number,
  whenBenefitAmount?: number,
  shareDate?: string
}
export type UserData = {
  uid: string;
  lastLogin: string;
  name: Name;
  email: string;
  phoneNumber?: string;
  admin?: boolean;
  companies?: string[];
  id?: string;
  ref?: DocumentReference<DocumentData>;
  dob?: string;
  gender?: string;
  address?: Address;
  syncEmployeeId?: string;
  companyId?: string;
}
export type CompanyUser = {
  userId: string,
  name: Name,
  email: string,
  inviteEmail?: string,
  roles: string[],
  active: boolean,
  createdAt?: Moment,
  updatedAt?: Moment,
  updatedBy?: string,
  updatedByUid?: string,
  id?: string,
  ref?: DocumentReference<CompanyUser>;
}
export type Name = {
  first: string;
  last: string;
  display: string;
  lowercase: string;
}
export type Address = {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}
export enum CompanyUserRoles {
  ADMIN = "admin",
  EXIT = "exit",
  APPROVER = "approver",
  VIEW = "view only"
}
export enum SeveranceBenefitTerm {
  TOTAL = "Total",
  YEARLY = "Yearly"
}
export enum SeveranceConditionType {
  TENURE,
  AGE
}
export enum SeveranceBenefitType {
  PAY = "Pay",
  // SERVICE = "Service",
  CASH = "Cash",
  COBRA = "Subsidized Cobra",
  MANAGED_HEALTH = "Managed Health",
  INSURED_COBRA = "Insured Cobra",
}

//added by Taty
export enum SeveranceBenefitStructure {
  FLAT_AMOUNT = "FLAT_AMOUNT",
  FLAT_AMOUNT_BY_COVERAGE = "FLAT_AMOUNT_BY_COVERAGE",
  FIXED_PERIODS = "FIXED_PERIODS",
  CUSTOM_PERIODS = "CUSTOM_PERIODS",
}

export enum USAStateList {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  FL = "Florida",
  GA = "Georgia",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming",
  DC = "Washington, D.C."
}

export enum WrittenLanguages {
  English = "English",
  Spanish = "Spanish",
  Arabic = "Arabic",
  Chinese = "Chinese",
  FrenchCreole = "French Creole",
  French = "French",
  German = "German",
  Gujarati = "Gujarati",
  Hindi = "Hindi",
  Korean = "Korean",
  Polish = "Polish",
  Portuguese = "Portuguese",
  Russian = "Russian",
  Tagalog = "Tagalog",
  Urdu = "Urdu",
  Vietnamese = "Vietnamese"
}

export enum SpokenLanguages {
  English = "English",
  Spanish = "Spanish",
  Arabic = "Arabic",
  Chinese = "Chinese",
  FrenchCreole = "French Creole",
  French = "French",
  German = "German",
  Gujarati = "Gujarati",
  Hindi = "Hindi",
  Korean = "Korean",
  Polish = "Polish",
  Portuguese = "Portuguese",
  Russian = "Russian",
  Tagalog = "Tagalog",
  Urdu = "Urdu",
  Vietnamese = "Vietnamese"
}

export type CustomData = {
  iconColor: string;
  tintColor: string;
  tintColorOpacity: number;
  customerName: string;
  whenCompanyLogo: string;
  companyLogo?: string;
  bannerImage: string;
  headerText: string;
  heroParagraph1: string;
  heroParagraph2: string;
}

export enum LandingPageType {
  EXISTING_EMPLOYEE = "Exiting employee",
  ACTIVE_EMPLOYEE = "Active employee",
  STUDENT = "Student"
}

export enum HealthInsuranceDecisionType {
  ENROLLED_PHONE_WHEN = "Enrolled Phone When",
  ENROLLED_PHONE_VELAPOINT = "Enrolled Phone Velapoint",
  ENROLLED_VIA_QUOTIT = "Enrolled via Quotit",
  ENROLLED_EXTERNALLY = "Enrolled Externally",
  SPOUSE_PLAN = "Spouse Plan",
  PARENT_PLAN = "Parent Plan",
  NEW_EMPLOYER_COVERAGE = "New Employer Coverage",
  DECLINED_COVERAGE_NO_REASON = "Declined Coverage No Reason",
  ENROLLED_IN_MEDICARE_OR_MEDICAID = "Enrolled in Medicare or Medicaid",
  WAS_NOT_ENROLLED_IN_COMPANY_PLAN = "Was not enrolled in company plan",
  ELECTED_COBRA = "Elected COBRA",
  UNKNOWN = "Unknown",
}

export enum COBRAElectionReasonType {
  ACCUMULATORS = "Accumulators (deductible, out-of-pocket max)",
  PROVIDER_ACCESS = "Provider access",
  PRESCRIPTION_DRUG_COVERAGE = "Prescription drug coverage",
  AVOID_COVERAGE_GAP = "Avoid coverage gap",
  ONGOING_CARE = "Ongoing care",
  NO_REASON_PROVIDED = "No reason provided",
  NONE = "",
}

export enum TaskTypes {
  SIMPLE = "Simple",
}
export const HIPAAViewReasons = {
  customerSupport: "customerSupport",
  legal: "legal",
  security: "security",
  benefitAdmin: "benefitAdmin",
  customerFollowUp: "customerFollowUp",
  reimbursementProcessing: "reimbursementProcessing",
  enrollmentSupport: "enrollmentSupport",
  productManagement: "productManagement",
  testing_debugging: "testing_debugging"
}
export type HIPAAViewReasonsType = keyof typeof HIPAAViewReasons;

export function HIPAAHumanReasons(reason: HIPAAViewReasonsType | string) {
  switch (reason) {
    case HIPAAViewReasons.reimbursementProcessing:
      return "Reimbursement processing";
    case HIPAAViewReasons.enrollmentSupport:
      return "Enrollment support";
    case HIPAAViewReasons.productManagement:
      return "Product management";
    case HIPAAViewReasons.testing_debugging:
      return "Testing/Debugging";
    case HIPAAViewReasons.customerSupport:
      return "Customer Support";
    case HIPAAViewReasons.legal:
      return "Legal";
    case HIPAAViewReasons.security:
      return "Security";
    case HIPAAViewReasons.benefitAdmin:
      return "Benefits Administration";
    case HIPAAViewReasons.customerFollowUp:
      return "Customer Follow Up";
    default:
      return "Other";
  }
}

export type Case = {
  name: string;
  companyId?: string;
  childCompany?: {
    name: string;
    id: string;
    address: {
      city: string;
      state: string;
      zip: string;
      street1: string;
      street2?: string;
    }
  };
  contact: {
    firstName: string;
    lastName: string;
    email?: string;
    phone?: string;
    dateOfBirth?: string;
    address?: {
      street1: string;
      street2: string;
      city: string;
      state: string;
      zip: string;
    };
  };
  cobranding: {
    logo: string;
    name: string;
    companyCode?: string;
    subCompanyCode?: string;
  };
  services?: [];
  event: {
    type: "termination" | "cobra" | "graduation";
    date: string;
    termination?: {
      type: "voluntary" | "involuntary";
      reason?: CaseReason;
    };
    cobra: {
      qualifyingEvent: "termination" | "divorce" | "death" | "ageOut" | "none";
      coverageEnd?: string;
      estimate?: number;
      currentContribution?: number;
    };
  };
  employee: {
    companyId?: string;
    id: string;
    name: string;
    number: string;
    email: string;
    phone: string;
    dateOfBirth: string;
    home: {
      street1?: string;
      street2?: string;
      city?: string;
      state?: string;
      zip: string;
    };
    lastName?: string;
    cobra: {
      estimate: number;
      currentContribution: number;
    };
    benefitTier?: string;
    terminationDate?: string;
    lastDay?: string;
  };
  tasks: {
    open: number;
    closed: number;
    total: number;
  };
  syncUser?: {
    id: string;
    date: Moment;
  }
  household?: Members;
  status: CaseStatus;
  sharedStatus?: SharedStatus;
  klaviyoCaseShared?: boolean;
  createdAt: Moment;
  createdBy: string;
  updatedAt: Moment;
  updatedBy: string;
  secure?: boolean;
  closeDate?: Moment;
  scheduled?: boolean;
  shared?: string;
  id?: string;
  terminationDate?: string;
  lastDay?: string;
  terminationType?: string;
  reason?: string;
  healthInsuranceDecision?: HealthInsuranceDecisionType;
  cobraElectionReason?: COBRAElectionReasonType;
  exitMeetingDate?: string;
  ref?: DocumentReference<Case>;
  released?: Moment;
  benefitTier: string;
  dataVersion?: string;
};
export enum CaseEventType {
  involuntaryTermination = "INVOLUNTARY_TERMINATION",
  voluntaryTermination ="VOLUNTARY_TERMINATION",
  dependentLossOfCoverage = "DEPENDENT_LOSS_OF_COVERAGE",
  familySeparation ="FAMILY_SEPARATION",
  hoursReduction = "HOURS_REDUCTION",
  medicareEligible = "MEDICARE_ELIGIBLE",
  lossOfCoverage = "LOSS_OF_COVERAGE",
  termination ="termination"
}
export type CaseDocument = {
  name: string;
  body: string;
  active: boolean;
  status: CaseDocumentStatus;
  visibility: CaseDocumentVisibility;
  signatureRequired: boolean;
  templateId: string;
  createdAt?: Moment;
  createdBy: string;
  updatedAt?: Moment;
  updatedBy?: string;
  id?: string;
  ref?: DocumentReference<CaseDocument>;
}
export enum CaseDocumentVisibility {
  EMPLOYEE = "employee",
  INTERNAL = "internal",
}
export enum CaseDocumentStatus {
  DRAFT = "draft",
  FINAL = "final",
}
export type CaseActivity = {
  activity: CaseActivityTypes;
  type: string;
  userId: string;
  caseId: string;
  companyId: string;
  displayName: string;
  message: string;
  timestamp: Moment;
  changes?: CaseActivityChange[];
  taskId?: string,
  documentId?: string,
  benefitId?: string,
  id?: string;
  ref?: DocumentReference<CaseActivity>;
}

export interface Person {
  dob?: string;
  relationship?: string;
  gender?: string;
  pregnant?: boolean;
  tobacco?: boolean;
  name?: string;
  livesWithUser?: string;
  new?: boolean;
  createdAt?: string
}

export type AdminCaseActivity = CaseActivity & {
  accessedBy: string;
}

export type UserActivity = {
  activity: UserActivityTypes;
  type: string,
  userId: string,
  timestamp: Moment;
  message?: string,
  id?: string;
  ref: DocumentReference<UserActivity>;
}

export enum UserActivityTypes {
  REGISTERED = "registered",
  LOGGEDIN = "loggedIn",
  LOGGEDOUT = "loggedOut",
  UPDATEDSETTINGS = "updatedSettings",
  USEDJAMIE = "usedJamie",
  VIEWEDHEALTHINSURANCEDETAILS = "viewedHealthInsuranceDetails",
  CALLEDCALLCENTER = "calledCallCenter",
  VIEWEDWHENBENEFIT = "viewedWhenBenefit",
}

export enum IncomeType {
  Job = 'Job',
  SelfEmployment = 'Self-Employment',
  Unemployment = 'Unemployment',
  Alimony = 'Alimony',
  SocialSecurity = 'Social Security',
  Retirement = 'Retirement',
  Pension = 'Pension',
  CapitalGains = 'Capital Gains',
  Investment = 'Investment',
  Scholarship = 'Scholarship',
  CourtAwards = 'Court Awards',
  CanceledDebt = 'Canceled Debt',
  Other = 'Other'
}
export enum ExpenseType {
  Alimony = 'Alimony',
  StudentLoanInterest = 'Student Loan Interest',
  Other = 'Other'
}
export enum HowOftenType {
  Yearly = 'Yearly',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Daily = 'Daily',
  Hourly = 'Hourly',
  EveryTwoWeeks = 'Every two weeks',
  TwicePerMonth = 'Twice per month'
}

export type CompanyPrivateSetting = {
  billCustomerId: string;
  invoice: {
    interval: string;
    preference?: string[];
  };
  fees: {
    subscription: number;
    whenBenefit: number;
  };
  automation: {
    employerPlatformLessWorkflowEnable: boolean;
    numberOfDays: number;
    errorNotificationContactEmail: string;
  }
}
interface imageType {
  name: string,
  path: string,
  type: string,
}
export interface companyBranding {
  bannerImage?: imageType;
  bannerTintColor: string,
  bannerTintTransparency: number,
  customerLogoColor?: imageType,
  customerLogoWhite?: imageType,
  primaryIconColor: string,
  organizationName: string,
  header: string,
  heroParagraph1: string,
  heroParagraph2: string,
}
export interface ServiceMarketPlaceType {
  id?: string,
  serviceName: string,
  serviceCategory: Array<string>,
  serviceProvider: string,
  tileHeader: string,
  tileBodyText: string,
  CTAButton?: boolean,
  CTAButtonText?: string,
  CTAButtonURL?: string,
  redeemCode?: string,
  phoneButton?: boolean,
  phoneNumber?: string,
  fontColor: string,
  tilesVisibility?: string,
  isActive: boolean,
  createdAt?: Moment,
  logoFile: imageType,
  headerPhoto: imageType,
}

export enum ServiceCategory {
  FINANCIAL = "Financial Services",
  CAREER = "Career Services",
  MENTAL = "Mental Health",
  HEALTH = "Health Insurance Services",
}
export type RequestTiming = {
  value: number;
  measure: string;
}
export type WhenBenefitSetting = {
  timing: {
    betweenRequests: RequestTiming;
    firstRequest: RequestTiming;
  }
}
export type CaseActivityChange = {
  field: string;
  oldValue?: string;
  newValue?: string;
}
export enum CaseActivityTypes {
  COMMENT = "comment",
  STATUS_CHANGE = "status_change",
  TASK_CREATED = "task_created",
  TASK_COMPLETED = "task_completed",
  TASK_REOPENED = "task_reopened",
  TASK_DELETED = "task_deleted",
  TASK_UPDATED = "task_updated",
  EMPLOYEE_EXITED = "employee_exited",
  CASE_CREATED = "case_created",
  CASE_CLOSED = "case_closed",
  CASE_REOPENED = "case_reopened",
  CASE_DELETED = "case_deleted",
  CASE_UPDATED = "case_updated",
  DOCUMENT_UPLOADED = "document_uploaded",
  DOCUMENT_DELETED = "document_deleted",
  DOCUMENT_UPDATED = "document_updated",
  DOCUMENT_DOWNLOADED = "document_downloaded",
  DOCUMENT_VIEWED = "document_viewed",
  DOCUMENT_EE_VIEWED = "document_ee_viewed",
  DOCUMENT_EE_SIGNED = "document_signed",
  DOCUMENT_EE_REJECTED = "document_rejected",
  DOCUMENT_COMPLETED = "document_completed",
  DOCUMENT_SENT = "document_sent",
  DOCUMENT_EXPIRED = "document_expired",
  DOCUMENT_REMINDER_SENT = "document_reminder_sent",
  SEVERANCE_PACKAGE_IMPORTED = "severance_package_imported",
  SEVERANCE_PACKAGE_BENEFIT_CREATED = "severance_package_benefit_created",
  SEVERANCE_PACKAGE_BENEFIT_UPDATED = "severance_package_benefit_updated",
  SEVERANCE_PACKAGE_BENEFIT_DELETED = "severance_package_benefit_deleted",
}
export enum CaseStatus {
  OPEN = "open",
  COMPLETED = "complete",
  CANCELLED = "cancelled",
}

export enum SharedStatus {
  SUCCESS = "success",
  ERROR = "error"
}
/**
 * If you add a new reason, make sure to add it to the VoluntaryReasons array
 * additionally update companies repo as the terminationType is determined by the reason
 */
export enum CaseReason {
  RESIGNATION = "resignation",
  LEAVE_NO_RETURN = "leave of absence - no return",
  ABANDONED = "abandoned",
  RETIREMENT = "retirement",
  QUIT = "quit",
  CRIME = "crime",
  DECEASED = "deceased",
  CONTRACT_ENDED = "contract ended",
  NEGLIGENCE = "negligence",
  WORK_AUTH = "work auth",
  REDUNDANCY = "redundancy",
  PERFORMANCE = "performance",
  LAYOFF = "layoff",
  RIF = "RIF",
  // TERMINATION = "termination",
  OTHER = "other",
}
export const VoluntaryReasons = [
  CaseReason.ABANDONED,
  CaseReason.CONTRACT_ENDED,
  CaseReason.QUIT,
  CaseReason.RESIGNATION,
  CaseReason.RETIREMENT,
  CaseReason.LEAVE_NO_RETURN,
  "ABANDONED",
  "CONTRACT_ENDED",
  "QUIT",
  "RESIGNATION",
  "RETIREMENT",
  "LEAVE_NO_RETURN",
]
export const CaseReasonText = (reason: CaseReason | string) => {
  if (typeof reason === "string") reason = reason as CaseReason;
  switch (reason) {
    case CaseReason.ABANDONED:
    case "ABANDONED":
      return "Abandoned";
    case CaseReason.CONTRACT_ENDED:
    case "CONTRACT_ENDED":
      return "Contract Ended";
    case CaseReason.CRIME:
    case "CRIME":
      return "Crime";
    case CaseReason.DECEASED:
    case "DECEASED":
      return "Deceased";
    case CaseReason.LAYOFF:
    case "LAYOFF":
      return "Layoff";
    case CaseReason.LEAVE_NO_RETURN:
    case "LEAVE_NO_RETURN":
      return "Leave of Absence - No Return";
    case CaseReason.NEGLIGENCE:
    case "NEGLIGENCE":
      return "Negligence";
    case CaseReason.OTHER:
    case "OTHER":
      return "Other";
    case CaseReason.PERFORMANCE:
    case "PERFORMANCE":
      return "Performance";
    case CaseReason.QUIT:
    case "QUIT":
      return "Quit";
    case CaseReason.REDUNDANCY:
    case "REDUNDANCY":
      return "Redundancy";
    case CaseReason.RESIGNATION:
    case "RESIGNATION":
      return "Resignation";
    case CaseReason.RETIREMENT:
    case "RETIREMENT":
      return "Retirement";
    case CaseReason.RIF:
    case "RIF":
      return "RIF";
    // case CaseReason.TERMINATION:
    // case "TERMINATION":
    //   return "Termination";
    case CaseReason.WORK_AUTH:
    case "WORK_AUTH":
      return "Work Authorization";
    default:
      return "Other";
  }
}
export interface WhenBenefit {
  companyId: string,
  company: string,
  name: string,
  exitCode: string,
  type: SeveranceBenefitType.MANAGED_HEALTH | SeveranceBenefitType.INSURED_COBRA,
  status: "active" | "exhausted" | "expired",
  time: {
    betweenRequests: {
      value: number,
      measure: "days" | "months" | "weeks" | "years",
    },
    firstRequest: {
      value: number,
      measure: "days" | "months" | "weeks" | "years",
    },
  },
  maxBenefit: number,
  remainingBenefit: number,
  createdAt: Moment,
  updatedAt: Moment,
  terminationDate: Moment,
  id: string,
  ref?: DocumentReference<WhenBenefit>,
}
export type BenefitAmounts = {
  [key: string]: {
    approved: number;
    pending: number;
    remaining: number;
    total: number;
  };
};
export interface ReimbursementRequest {
  whenBenefitId: string,
  companyId: string,
  company: string,
  name: string,
  notes?: string,
  amount: number,
  approvedAmount?: number,
  approvedById?: string,
  approvedBy?: string,
  approvedOn?: Moment | null,
  denialReason?: DenialReason,
  coverageStartDate: Moment | null,
  coverageEndDate: Moment | null,
  status: "Pending" | "Additional Information Requested" | "Cancelled" | "Approved" | "Partially Approved" | "Denied" | "Requesting payment details" | "Additional info needed",
  createdAt: Moment,
  updatedAt: Moment,
  files: Array<{
    name: string,
    path: string,
    type: string,
  }>,
  id?: string,
  userId?: string,
  ref?: DocumentReference<ReimbursementRequest>,
}

export enum DenialReason {
  NON_QUALIFIED_PURCHASE = "Non-qualified purchase",
  DUPLICATE = "Duplicate",
  INSUFFICIENT_INFORMATION = "Insufficient Information",
  FUNDS_EXHAUSTED = "Funds Exhausted",
  ACTIONS_REQUIRED = "Actions Required",
}
export interface CsvData {
  employeeId: string;
  companyId: string;
}

export interface HrisConnection {
  name?: string
  logo?: string
  squareLogo?: string
  status?: string
  lastImport: number
  createdOn?: number
}
export interface Employee {
  uid: string,
  companyId: string;
  firstName?: string,
  lastName?: string,
  termination?: TerminationData,
  openCases?: number,
  flsa?: string,
  // name: {
  //   first: string,
  //   last: string,
  //   display: string,
  //   full: string
  // },
  cobra?: {
    estimate: number,
    currentContribution: number,
  },
  startDate: Moment,
  payFrequency: string,
  payCurrency: string,
  payPeriod: string,
  payRate: number,
  dateOfBirth: string,
  terminationDate?: Moment,
  email: string,
  personalEmail: string,
  workEmail: string,
  phone: string,
  status: string,
  employeeNumber: string,
  benefit: number,
  whenBenefit: boolean,
  benefitTier: BenefitTier,
  exitCode: string,
  home: {
    street1: string,
    street2: string,
    city: string,
    state: string,
    zip: string,
    country: string,
  },
  manager: {
    id: string,
    employeeNumber: string,
    firstName: string,
    lastName: string
  }
  work: {
    name: string,
    street1: string,
    street2: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    phone: string,
  },
  team?: {
    id: string,
    name: string,
    type: string
  },
  department?: {
    id: string,
    name: string,
  },
  gender: string,
  ethnicity: string,
  maritalStatus: string,
  salary: number,
  employeeType: string,
  title: string,
  hris: any,
  id: string,
  ref?: DocumentReference<DocumentData>,
}
export enum EmployeeBenefitType {
  DENTAL = "Dental",
  DISABILITY = "Disability",
  FSA = "FSA",
  HRA = "HRA",
  HSA = "HSA",
  LIFE = "Life",
  MEDICAL = "Medical",
  RETIREMENT = "Retirement",
  VISION = "Vision",
  WHEN = "When Coverage",
  OTHER = "Other",
}
interface Member {
  dob: string;
  name: string;
  relationship: "self" | "child" | "spouse";
  gender: "male" | "female";
  tobacco?: boolean;
  pregnant?: boolean
}

export interface Members {
  self: Member;
  [key: string]: Member;
}
export interface Plan {
  nickname?: string,
  availableBenefit: number,
  maxBenefit: number,
  type: string,
  employer?: string,
  paymentType: string,
  paymentDueDate?: Moment,
  premium: number,
  id?: string,
  ref?: DocumentReference<DocumentData>,
}
export enum InvoiceInterval {
  Immediate = "Immediate",
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly"
}

export interface BenefitPlan {
  name: string,
  carrier: string,
  plan: string[],
  planNetworkType: string,
  planStartDate: Moment | null,
  planEndDate: Moment | null,
  coverageTierStructure: string,
  individualDeductible: number,
  familyDeductible: number,
  individualOutOfPocketMaximum: number,
  familyOutOfPocketMaximum: number,
  fourTierPremium?: {
    employeeOnly?: coverageTierPremiumTypes,
    employeePlusSpouse?: coverageTierPremiumTypes,
    employeePlusChildren?: coverageTierPremiumTypes,
    family?: coverageTierPremiumTypes
  },
  threeTierPremium?: {
    employeeOnly?: coverageTierPremiumTypes,
    employeePlusOneDependent?: coverageTierPremiumTypes,
    employeePlusMoreDependent?: coverageTierPremiumTypes
  }
  id?: string,
  createdAt: Moment
  updatedAt: Moment
  deletedAt?: Moment
}

export interface HrisIntegration {
  id?: string
  name: string
  logo?: string
  squareLogo?: string
  integrationMethod: string
  integrationMethodId: string
  active: boolean
  ref?: DocumentReference<DocumentData>
}

export enum CarrierTypes {
  Aetna = "Aetna",
  Cigna = "Cigna",
  KaiserPermanente = "Kaiser Permanente",
  UnitedHealthCare = "United Health Care",
  Humana = "Humana",
  AnthemBlueCross = "Anthem Blue Cross",
  Wellmark = "Wellmark",
  Guardian = "Guardian"
}

export enum BenefitPlanType {
  Medical = "Medical",
  Dental = "Dental",
  Vision = "Vision",
  Other = "Other",
}

export enum CoverageTierStructureTypes {
  FourTier = "4-Tier",
  ThreeTier = "3-Tier",
}

export enum PlanNetworkType {
  PPO = "PPO",
  HMO = "HMO",
  EPO = "EPO",
  HDHP = "HDHP",
  POS = "POS",
}

export enum IntegrationsTypes {
  Merge = "Merge",
  Finch = "Finch",
  Isolved = "Isolved",
  PrismHr = "PrismHr",
}

export interface coverageTierPremiumTypes {
  totalPremium?: number,
  employerContribution?: number,
  employeeContribution?: number,
}

export const generateDescription = (benefit: SeveranceBenefit, benefitTier: string | undefined): string => {
  let benefitDescription = "";
  // console.log('where is',benefit)
  if (!benefit.calculation) {
    return ""
  }

  if (benefit.type === SeveranceBenefitType.PAY) {
    // Description for SeveranceBenefitType.PAY
    if (benefit.structure === SeveranceBenefitStructure.FLAT_AMOUNT) {
      benefitDescription = `This benefit configuration provides a flat dollar amount of $${benefit.calculation.flatAmount} dollars in severance pay.`;
    } else if (benefit.structure === SeveranceBenefitStructure.FIXED_PERIODS) {
      benefitDescription = `This benefit configuration provides severance pay in the amount of ${benefit.calculation.percent}% of regular pay in severance for ${benefit.calculation.periods} ${benefit.calculation.periodMeasure}.`;
    } else if (benefit.structure === SeveranceBenefitStructure.CUSTOM_PERIODS) {
      benefitDescription = `This benefit configuration provides ${benefit.calculation.percent}% of regular pay for each year of service rounded ${benefit.calculation.round}.`;
    }
  } else if (benefit.type === SeveranceBenefitType.CASH) {
    // Description for SeveranceBenefitType.CASH
    benefitDescription = `This benefit configuration provides a flat dollar amount of $${benefit.calculation.flatAmount} dollars in cash.`;
  } else if (benefit.type === SeveranceBenefitType.COBRA) {
    // Description for SeveranceBenefitType.COBRA
    if (benefit.structure === SeveranceBenefitStructure.FLAT_AMOUNT) {
      benefitDescription = `This benefit configuration provides a flat dollar amount of $${benefit.calculation.flatAmount} dollars in COBRA subsidy.`;
    } else if (benefit.structure === SeveranceBenefitStructure.FIXED_PERIODS) {
      benefitDescription = `This benefit configuration provides ${benefit.calculation.percent}% in COBRA premium for ${benefit.calculation.periods} months.`;
    } else if (benefit.structure === SeveranceBenefitStructure.CUSTOM_PERIODS) {
      benefitDescription = `This benefit configuration provides ${benefit.calculation.percent}% in monthly COBRA premium for each year of service rounded ${benefit.calculation.round}.`;
    }
  } else if (benefit.type === SeveranceBenefitType.MANAGED_HEALTH) {
    // Description for SeveranceBenefitType.CASH
    if (benefit.structure === SeveranceBenefitStructure.FLAT_AMOUNT_BY_COVERAGE) {
      const individualAmount: number | undefined = benefit?.calculation?.individualAmount;
      const formattedIndividualAmount: string = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(individualAmount ?? 0);
      const familyAmount: number | undefined = benefit?.calculation?.familyAmount;
      const formattedFamilyAmount: string = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(familyAmount ?? 0)
      benefitDescription = `This benefit configuration provides a flat dollar amount of Health Insurance Reimbursement in the amount of ${formattedIndividualAmount} for single employees and ${formattedFamilyAmount} for employees with families.`;
    }
    else if (benefit.structure === SeveranceBenefitStructure.FLAT_AMOUNT) {
      let amount;
      if (benefitTier === BenefitTier.individual) {
        amount = benefit?.calculation?.individualAmount
      } else if (benefitTier === BenefitTier.family) {
        amount = benefit?.calculation?.familyAmount
      } else {
        amount = benefit?.calculation?.flatAmount
      }
      const formattedAmount: string = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(amount ?? 0);
      benefitDescription = `This benefit configuration provides a flat dollar amount of  ${formattedAmount} in health insurance reimbursements`;
    }
  }

  return benefitDescription;
};

export function formatPhoneNumber(phoneNumber: any) {
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  const formattedNumber = digitsOnly.replace(/^(\d{1})(\d{3})(\d{3})(\d{4})$/, '+$1 $2 $3 $4');
  return formattedNumber;
}

export interface Person {
  dob?: string;
  name?: string;
  relationship?: string;
  gender?: string;
  tobacco?: boolean;
  pregnant?: boolean;
  livesWithUser?: string;
  new?: boolean;
  createdAt?: string;
  offeredHRA?: boolean;
  offeredICHRA?: boolean;
  offeredICHRAOrQSEHRA?: boolean;
  [key: string]: any;
}
export interface ApplicationHousehold {
  members: Members;
  married?: boolean;
  planFederalITR?: boolean;
  provideTaxFilerInfo?: boolean;
  jointSpouseTax?: boolean;
  checkForPremiumTax?: boolean;
  willTaxDependentBySomeone?: boolean;
  liveWithUnder19IfNotMarried?: boolean;
  claimingDependents?: boolean;
}
export interface Application {
  enrollmentSite?: string;
  externalApplicationId?: string;
  status: ApplicationStatus;
  uid: string; // The submitting user's ID
  contact: Contact; // Contact info of the person submitting this
  plan: {
    id: string;
    type: string;
    expectedPremium: number;
    expectedSubsidy: number;
    [key: string]: any; // Additional plan details as needed. NOTE: This is where most dynamism will hinge. TBD whether Zod will prove useful to handle this.
  };
  consent: Array<{
    consentedOn: Moment | null;
    name: string;
    consentText: string;
  }>;
  data: any; // Data from the form, household details, subsidy details, etc.
  config: {
    insuranceType: string;
    additionalFields: string[];
    [key: string]: any; // Additional config details as needed
  };
  review: {
    adminId: string;
    notes: string;
    reviewedOn: Moment | null;
  };
  submission: {
    platform: string;
    platformId: string;
    submittedBy: string;
    submittedOn: Moment | null;
  };
  documents?: Array<Document>;
  requests: Array<{
    type: string;
    details: string;
    requiredBy: Moment | null;
  }>;
  createdAt: Moment;
  createdBy: string;
  updatedAt: Moment;
  updatedBy: string;
  id: string;
  ref?: DocumentReference<DocumentData>
}

export interface Document {
  name: string;
  storagePath: string;
  type: string;
  size: string;
  documentType: string;
}

export enum ApplicationStatus {
  DRAFT = "draft",
  SUBMITTED = "submitted",
  INPROGRESS = "in progress",
  ADDITIONALINFORMATIONNEEDED = "Additional information needed",
  SENTTOCARRIER = "Sent to carrier",
  PENDINGSIGNATURE = "pending signature",
  SIGNATURERECEIVED = "signature received",
  AWAITING_PAYMENT = "awaiting payment",
  ACCEPTED = "accepted",
  DENIED = "denied",
  EXPIRED = "expired",
  CANCELLED = "cancelled"
}